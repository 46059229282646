<template>
  <v-card rounded="0" flat>
    <h2 class="mt-1">{{ title }}</h2>
    <v-divider class="mt-0" />
    <v-form>
      <v-text-field
        label="İsim"
        required
        :readonly="readOnly"
        v-model="user.name"
      />
      <SMEmailTextfield :email="user.email" :required="true"></SMEmailTextfield>
      <SMPhoneSelectbox
        validatable
        :phone="user.phone"
        @update:phone="(phone) => (user.phone = phone)"
      ></SMPhoneSelectbox>
    </v-form>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import SMPhoneSelectbox from "@/view/content/siliconmade/SM-Phone-Selectbox.vue";
import SMEmailTextfield from "../siliconmade/SM-Email-Textfield.vue";

export default defineComponent({
  components: {
    SMPhoneSelectbox,
    SMEmailTextfield,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    newUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Kullanıcı Bilgileri",
    },
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
});
</script>

<style></style>
