<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2>Kullanıcı - Lead Eşleşme Listesi</h2>
      </v-toolbar-title>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-row class="pa-4" justify="space-between" v-if="!loading">
      <v-col cols="5">
        <v-treeview
          :active.sync="active"
          :items="items"
          :open.sync="open"
          activatable
          color="primary"
          open-on-click
          transition
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="!item.children"> mdi-account </v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-badge
              v-if="item.children && item.children === waitingApproval"
              color="red"
              :value="waitingApprovalCount > 0"
            >
              <template v-slot:badge>
                <strong>{{ waitingApprovalCount }}</strong>
              </template>
            </v-badge>
          </template>
        </v-treeview>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="d-flex text-center flex-wrap">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
          >
            Eşleşmeye hazır kullanıcıları görmek için bir lead seçin.
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-4 pl-4"
            flat
            max-width="550"
            width="550"
          >
            <v-btn
              @click="fetchMatchedUsers"
              v-if="fetchAgainButton"
              color="success"
              :loading="fetchAgainLoading"
              dark
              class="mb-5"
            >
              Bu Kullanıcıya Ait Diğer Eşleşmeleri Getir
            </v-btn>
            <v-card
              v-for="matched in selected.users"
              :key="matched.id"
              elevation="22"
              outlined
              class="mb-8 ml-2"
            >
              <v-toolbar height="35" class="pa-4 mb-5">
                <h4 class="ml-20">Yeni Lead Bilgileri</h4>
                <v-spacer></v-spacer>
                <h4 class="mr-16">Sistemdeki Eşleşen Kullanıcının Bilgileri</h4>
              </v-toolbar>
              <v-divider></v-divider>
              <v-row class="d-flex justify-content-center">
                <v-col cols="5">
                  <v-row class="text-left">
                    <v-col class="mb-1" tag="strong" cols="4"> İsim: </v-col>
                    <v-col cols="8">
                      <span v-on="on" v-bind="attrs">
                        {{ selected.name ?? "İsim Yok" }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="text-left">
                    <v-col class="mb-1" tag="strong" cols="4"> E-Posta: </v-col>
                    <v-col cols="8">
                      <span v-on="on" v-bind="attrs">
                        {{ selected.email }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="text-left">
                    <v-col class="mb-1" tag="strong" cols="4"> Telefon: </v-col>
                    <v-col cols="8">
                      <span v-on="on" v-bind="attrs">
                        {{ selected.phone ?? "Telefon Yok" }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  class="d-flex flex-column justify-content-center item-aligns-center"
                  cols="1"
                >
                  <v-row justify="center">
                    <v-icon
                      :color="
                        checkMatchedAttributes(selected.name, matched.name)
                          .color
                      "
                    >
                      {{
                        checkMatchedAttributes(selected.name, matched.name).name
                      }}
                    </v-icon>
                  </v-row>
                  <v-row justify="center">
                    <v-icon
                      :color="
                        checkMatchedAttributes(selected.email, matched.email)
                          .color
                      "
                    >
                      {{
                        checkMatchedAttributes(selected.email, matched.email)
                          .name
                      }}
                    </v-icon>
                  </v-row>
                  <v-row justify="center">
                    <v-icon
                      :color="
                        checkMatchedAttributes(selected.phone, matched.phone)
                          .color
                      "
                    >
                      {{
                        checkMatchedAttributes(selected.phone, matched.phone)
                          .name
                      }}
                    </v-icon>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-row class="text-left">
                    <v-col class="mb-1" tag="strong" cols="4"> İsim: </v-col>
                    <v-col cols="8">
                      <span v-on="on" v-bind="attrs">
                        {{ matched.name ?? "İsim Yok" }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="text-left">
                    <v-col class="mb-1" tag="strong" cols="4"> E-Posta: </v-col>
                    <v-col cols="8">
                      <span v-on="on" v-bind="attrs">
                        {{ matched.email }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="text-left">
                    <v-col class="mb-1" tag="strong" cols="4"> Telefon: </v-col>
                    <v-col cols="8">
                      <span v-on="on" v-bind="attrs">
                        {{ matched.phone ?? "Telefon Yok" }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-content-around">
                <Matcher
                  @approveFromModal="(matchedId) => approveMatch(matchedId)"
                  :lead="selected"
                  :user="matched"
                >
                  <v-btn
                    color="primary"
                    class="white--text"
                    v-permission="{
                      permissions: ['leads-update'],
                    }"
                    v-if="selected.is_matched === 0"
                    small
                  >
                    Lead'i Sistemdeki Kullanıcıyla Eşleştir
                  </v-btn>
                </Matcher>
                <Create
                  @updateMatches="() => fetchMatchedUsers()"
                  :lead="selected"
                  :user="matched"
                >
                  <v-btn
                    color="info"
                    v-if="selected.is_matched === 0"
                    small
                    v-permission="{
                      permissions: ['leads-update'],
                    }"
                  >
                    Yeni Kullanıcı Oluştur
                  </v-btn>
                </Create>

                <v-btn
                  color="error"
                  class="white--text"
                  @click="showRemoveMatchModal(matched.matchedId)"
                  v-permission="{
                    permissions: ['leads-update'],
                  }"
                  v-if="selected.is_matched === 1"
                  small
                >
                  Eşleştirmeyi Kaldır
                </v-btn>
              </v-row>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
        <v-row
          class="w-100 align-self-center"
          justify="space-between"
          v-if="!loading && selected"
        >
          <v-col>
            <v-toolbar height="35" class="pa-4 mb-5">
              <h4 class="">Eşleşen Kullanıcının Lead'leri</h4>
              <v-spacer></v-spacer>
            </v-toolbar>
            <ApplicationList
              v-if="selected"
              :user_id="
                selected && selected.users.length ? selected.users[0].id : null
              "
              hideNewLeadButton
              hideTitle
              colSize="6"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
      v-else
      style="height: 250px"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
        width="7"
      ></v-progress-circular>
      <h3 class="mt-5">Lütfen bekleyiniz...</h3>
    </div>
  </v-card>
</template>

<script>
import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import AxiosInstance from "@/core/services/axios.service.js";
import Matcher from "@/view/content/lead-match/Matcher.vue";
import Create from "@/view/content/lead-match/Create.vue";
import { isEmptyObject } from "jquery";
import ApplicationList from "@/view/pages/applications/ApplicationList.vue";

export default {
  name: "LeadsMatchList",
  components: {
    Matcher,
    Create,
    KTTopMenu,
    ApplicationList,
  },
  data: () => ({
    active: [],
    open: [],
    waitingApproval: [],
    approved: [],
    loading: true,
    fetchAgainButton: false,
    loader: null,
    fetchAgainLoading: false,
    matchedUser: [],
  }),

  computed: {
    items() {
      return [
        {
          name: "Eşleşme Bekleyen Leadler",
          children: this.waitingApproval,
        },
        {
          name: "Önceden Eşleştirilen Leadler",
          children: this.approved,
        },
      ];
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.waitingApproval[id] || this.approved[id];
    },
    waitingApprovalCount() {
      return this.waitingApproval.filter((item) => item !== isEmptyObject(item))
        .length;
    },
  },
  async created() {
    await this.fetchMatchedUsers();
    if (this.$route.query.active) {
      this.active[0] = this.$route.query.active;
      this.checkIsThereAnyMatchedUser(this.active[0]);
    }
  },
  methods: {
    fetchMatchedUsers(fromButton = false) {
      if (fromButton) {
        this.loader = "fetchAgainLoading";
      }
      AxiosInstance.get("/lead-user-matches")
        .then((response) => {
          this.waitingApproval = this.filterLeadsUsers(
            response.data.filter((item) => item.is_matched === 0)
          );
          this.approved = this.filterLeadsUsers(
            response.data.filter((item) => item.is_matched === 1)
          );
        })
        .catch(() => {
          this.$toast.error("Eşleşme bilgileri alınamadı.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
          if (this.fetchAgainButton) {
            this.fetchAgainButton = false;
          }
          if (fromButton) {
            this[this.loader] = false;
            this.loader = null;
          }
        });
    },
    approveMatch(matchedId) {
      AxiosInstance.post(`/lead-user-matches/${matchedId}`)
        .then(() => {
          this.$toast.success("Eşleşme onaylandı.", {
            position: "bottom-right",
            duration: 2000,
          });
          this.fetchMatchedUsers();
        })
        .catch(() => {
          this.$toast.error("Eşleşme onaylanamadı.", {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
    async checkIsThereAnyMatchedUser(lead_id) {
      const { data } = await AxiosInstance.post("lead-user-match-list", {
        lead_id: lead_id,
      });
      if (data) {
        this.fetchAgainButton = true;
        return;
      }
    },
    showRemoveMatchModal(matchedId) {
      const params = {
        title: "Eşleşmeyi Kaldır",
        text: "Eşleşme kaldırıldığında eğer varsa lead'e ait silinmiş eşleşmeler geri gelir.Eşleşmeyi kaldırmak istediğinize emin misiniz?",
        onConfirm: () => this.removeMatch(matchedId),
      };
      this.$approvalModal.show(params);
    },
    removeMatch(matchedId) {
      AxiosInstance.post(`/lead-user-matches/unmatch/${matchedId}`)
        .then(() => {
          this.$toast.success("Eşleşme kaldırıldı.", {
            position: "bottom-right",
            duration: 2000,
          });
          this.fetchMatchedUsers();
        })
        .catch(() => {
          this.$toast.error("Eşleşme kaldırılamadı.", {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
    filterLeadsUsers(data) {
      const processedData = [];
      data.forEach((item) => {
        const leadId = item.lead_id;

        if (!processedData[leadId]) {
          processedData[leadId] = {
            id: leadId,
            name: item.lead.name,
            email: item.lead.email,
            phone: item.lead.phone,
            is_matched: item.is_matched,
            users: [],
          };
        }
        this.waitingApprovalCount++;
        if (item.user) {
          processedData[leadId].users.push({
            id: item.user_id,
            name: item.user.name,
            email: item.user.email ? item.user.email.email : null,
            phone: item.user.phone,
            matchedId: item.id,
          });
        }
      });
      return processedData;
    },
    checkMatchedAttributes(leadItem, userItem) {
      let icon = {};
      if (leadItem === userItem) {
        icon = {
          name: "mdi-check",
          color: "green",
        };
      } else {
        icon = {
          name: "mdi-close",
          color: "red",
          tooltip: leadItem,
        };
      }
      return icon;
    },
  },
  watch: {
    active() {
      this.checkIsThereAnyMatchedUser(this.active[0]);
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
  },
};
</script>
