<template>
  <v-dialog v-model="dialog" width="1200">
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <Card title="Yeni Kullanıcı Oluştur">
      <template #close>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <v-card flat class="px-4 py-8">
        <v-form ref="form" v-model="isFormValid">
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column justify-content-center px-12"
            >
              <Lead :readOnly="true" :disabled="true" :lead="lead" />
            </v-col>
            <v-divider vertical />
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-column justify-content-center px-12"
            >
              <User
                @update:user="(val) => (lead = val)"
                :user="lead"
                :newUser="true"
              />
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-content-end">
            <v-btn color="error" class="white--text" @click="dialog = false">
              Islemi Iptal Et
            </v-btn>
            <v-btn
              color="primary"
              class="white--text"
              @click="showApprovalModal('approve')"
            >
              Yeni Kullanıcı Oluştur
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </Card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import AxiosInstance from "@/core/services/axios.service";

import User from "./User.vue";
import Lead from "./Lead.vue";
import Card from "@/view/content/components/Card";

export default defineComponent({
  props: {
    lead: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      isFormValid: false,
    };
  },
  methods: {
    showApprovalModal() {
      const params = {
        title: "Yeni Kullanıcı Oluştur",
        text: "Yeni bir kullanıcı yaratma işlemini onaylamak istediğinize emin misiniz?",
        onConfirm: () => {
          return this.approve();
        },
      };
      this.$approvalModal.show(params);
    },
    approve() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        AxiosInstance.post("/lead-user-matches", {
          lead_id: this.lead.id,
          email: this.lead.email,
          name: this.lead.name,
          phone: this.lead.phone,
        })
          .then(({ data }) => {
            this.$toast.success(data.message, {
              duration: 2000,
              position: "bottom-right",
            });
            this.$emit("updateMatches");
            this.dialog = false;
          })
          .catch(({ response }) => {
            this.$toast.error(response.data.errors[0].detail, {
              duration: 2000,
              position: "bottom-right",
            });
          });
      }
    },
  },
  components: { Card, User, Lead },
});
</script>

<style></style>
