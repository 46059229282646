<template>
  <v-card flat rounded="0">
    <h2 class="mt-1">{{ title }}</h2>
    <v-divider class="mt-0" />
    <v-text-field
      label="İsim"
      :disabled="disabled"
      :readonly="readOnly"
      v-model="lead.name"
    />
    <v-text-field
      label="E-Posta"
      :disabled="disabled"
      :readonly="readOnly"
      v-model="lead.email"
    />
    <v-text-field
      label="Telefon"
      :disabled="disabled"
      :readonly="readOnly"
      v-model="lead.phone"
    />
  </v-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    lead: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Lead Bilgileri",
    },
  },
});
</script>

<style></style>
