<template>
  <v-dialog v-model="dialog" width="1200">
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <Card title="Lead Eşleme">
      <template #close>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <v-card flat class="px-4 py-8">
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column justify-content-center px-12"
          >
            <Lead
              :readOnly="true"
              :lead="lead"
              title="Yeni Lead'in Bilgileri"
            />
          </v-col>
          <v-divider vertical />
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column justify-content-center px-12"
          >
            <User
              :readOnly="true"
              :user="user"
              title="Eşleştirilecek Kullanıcının Bilgileri"
            />
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-content-end">
          <v-btn
            color="error"
            class="white--text"
            @click="showApprovalModal('reject')"
          >
            Reddet
          </v-btn>
          <v-btn
            color="primary"
            class="white--text"
            @click="showApprovalModal('approve')"
          >
            Onayla
          </v-btn>
        </v-card-actions>
      </v-card>
    </Card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";

import User from "./User.vue";
import Lead from "./Lead.vue";
import Card from "@/view/content/components/Card";

export default defineComponent({
  props: {
    lead: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    showApprovalModal(choice) {
      const params = {
        title: choice === "approve" ? "Onayla" : "Reddet",
        text:
          choice === "approve"
            ? "Seçili olan lead ve kullanıcı eşleme işlemini onaylamak istediğinize emin misiniz?"
            : "Seçili olan lead ve kullanıcı eşleme işlemini iptal etmek istediğinize emin misiniz?",
        onConfirm: () => {
          if (choice === "approve") return this.approve();
          return this.reject();
        },
      };
      this.$approvalModal.show(params);
    },
    reject() {
      this.dialog = false;
    },
    approve() {
      this.$emit("approveFromModal", this.user.matchedId);
      this.dialog = false;
    },
  },
  components: { Card, User, Lead },
});
</script>

<style></style>
